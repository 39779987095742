import { lazy } from 'react';

// project imports
import MainLayout from '../components/menu-admin';
import Loadable from '../components/Loadable';
import { ProtectedRoute } from "./ProtectedRoute";
import { mainListItems_o } from '../components/opengym/list-menu-admin';

//import { mainListItems_o } from './opengym/list-menu-admin';
//import { mainListItems_g } from './gym/list-menu-admin';
//import { mainListItems_u } from './user/list-menu-admin';

const DashboardOpengym = Loadable(lazy(() => import('../pages/admin/opengym/dashboard')));
const Config = Loadable(lazy(() => import('../pages/admin/opengym/config')));

const Usuarios = Loadable(lazy(() => import('../pages/admin/opengym/usuarios')));
const UsuarioEditar = Loadable(lazy(() => import('../pages/admin/opengym/usuarios/usuarios.editar')));
const UsuarioCadastrar = Loadable(lazy(() => import('../pages/admin/opengym/usuarios/usuarios.cadastrar')));

const Academia = Loadable(lazy(() => import('../pages/admin/opengym/academia')));
const AcademiaEditar = Loadable(lazy(() => import('../pages/admin/opengym/academia/academia.editar')));
const AcademiaCadastrar = Loadable(lazy(() => import('../pages/admin/opengym/academia/academia.cadastrar')));

const Location = Loadable(lazy(() => import('../pages/admin/opengym/location')));
const LocationCadastrar = Loadable(lazy(() => import('../pages/admin/opengym/location/location.cadastrar')));
const LocationEditar = Loadable(lazy(() => import('../pages/admin/opengym/location/location.editar')));

const Voucher = Loadable(lazy(() => import('../pages/admin/opengym/voucher')));
const VoucherEditar = Loadable(lazy(() => import('../pages/admin/opengym/voucher/voucher.editar')));
const VoucherCadastrar = Loadable(lazy(() => import('../pages/admin/opengym/voucher/voucher.cadastrar')));

const Newsletter = Loadable(lazy(() => import('../pages/admin/opengym/newsletter')));
const LeadsApp = Loadable(lazy(() => import('../pages/admin/opengym/leadsApp')));
const LeadsGym = Loadable(lazy(() => import('../pages/admin/opengym/leadsGym')));

const MktEnvio = Loadable(lazy(() => import('../pages/admin/opengym/mktEnvio')));
const MktEnvioCadastrar = Loadable(lazy(() => import('../pages/admin/opengym/mktEnvio/cadastrar')));

const MktSegmentacao = Loadable(lazy(() => import('../pages/admin/opengym/mktSegmentacao')));
const MktSegmentacaoForm = Loadable(lazy(() => import('../pages/admin/opengym/mktSegmentacao/form')));

const MktTemplate = Loadable(lazy(() => import('../pages/admin/opengym/mktTemplate')));
const MktTemplateEditar = Loadable(lazy(() => import('../pages/admin/opengym/mktTemplate/editar')));
const MktTemplateCadastrar = Loadable(lazy(() => import('../pages/admin/opengym/mktTemplate/cadastrar')));

const PaymentProfile = Loadable(lazy(() => import('../pages/admin/opengym/paymentProfile')));
const PaymentProfileEditar = Loadable(lazy(() => import('../pages/admin/opengym/paymentProfile/paymentProfile.editar')));
const PaymentProfileCadastrar = Loadable(lazy(() => import('../pages/admin/opengym/paymentProfile/paymentProfile.cadastrar')));

const Invoice = Loadable(lazy(() => import('../pages/admin/opengym/invoice')));
const InvoiceForm = Loadable(lazy(() => import('../pages/admin/opengym/invoice/form')));

const CheckinReversal = Loadable(lazy(() => import('../pages/admin/opengym/checkin.reversal')));
const CheckinReversed = Loadable(lazy(() => import('../pages/admin/opengym/checkin.reversed')));


// '/opengym/checkin.reversal'


const Account = Loadable(lazy(() => import('../pages/admin/shared/account/index')));



// ==============================|| MAIN ROUTING ||============================== //
// Validação do Token
// source = 1 - Area OpenGym
// source = 2 - Area Academia Adm
// source = 3 - Area Aluno

const OpenGymRoute = {

    path: '/',
    element: <MainLayout itens={mainListItems_o}/>,
    children: [
        {
            path: '/opengym/dashboard',
            element: <ProtectedRoute source='1'><DashboardOpengym /></ProtectedRoute>
        },
        {
            path: '/opengym/config',
            element: <ProtectedRoute  source='1'><Config /></ProtectedRoute>
        },
        {
            path: '/opengym/academia',
            element: <ProtectedRoute  source='1'><Academia /></ProtectedRoute>
        },
        {
            path: '/opengym/academia/cadastrar',
            element: <ProtectedRoute  source='1'><AcademiaCadastrar /></ProtectedRoute>
        },
        {
            path: '/opengym/academia/editar/:idAcademia',
            element: <ProtectedRoute  source='1'><AcademiaEditar /></ProtectedRoute>
        },
        {
            path: '/opengym/usuarios',
            element: <ProtectedRoute source='1'><Usuarios /></ProtectedRoute>
        },
        {
            path: '/opengym/usuarios/cadastrar',
            element: <ProtectedRoute source='1'><UsuarioCadastrar /></ProtectedRoute>
        },
        {
            path: '/opengym/usuarios/editar/:idUsuario',
            element: <ProtectedRoute  source='1'><UsuarioEditar /></ProtectedRoute>
        },
        {
            path: '/opengym/location',
            element: <ProtectedRoute  source='1'><Location /></ProtectedRoute>
        },
        {
            path: '/opengym/location/cadastrar',
            element: <ProtectedRoute  source='1'><LocationCadastrar /></ProtectedRoute>
        },
        {
            path: '/opengym/location/editar/:idLocation',
            element: <ProtectedRoute  source='1'><LocationEditar /></ProtectedRoute>
        },
        {
            path: '/opengym/voucher',
            element: <ProtectedRoute  source='1'><Voucher /></ProtectedRoute>
        },
        {
            path: '/opengym/voucher/cadastrar',
            element: <ProtectedRoute  source='1'><VoucherCadastrar /></ProtectedRoute>
        },
        {
            path: '/opengym/voucher/editar/:idVoucher',
            element: <ProtectedRoute  source='1'><VoucherEditar /></ProtectedRoute>
        },
        {
            path: '/opengym/newsletter',
            element: <ProtectedRoute  source='1'><Newsletter /></ProtectedRoute>
        },
        {
            path: '/opengym/leadsApp',
            element: <ProtectedRoute  source='1'><LeadsApp /></ProtectedRoute>
        },
        {
            path: '/opengym/leadsGym',
            element: <ProtectedRoute  source='1'><LeadsGym /></ProtectedRoute>
        },
        {
            path: '/opengym/mktEnvio',
            element: <ProtectedRoute  source='1'><MktEnvio /></ProtectedRoute>
        },
        {
            path: '/opengym/mktEnvio/cadastrar',
            element: <ProtectedRoute  source='1'><MktEnvioCadastrar /></ProtectedRoute>
        },        
        {
            path: '/opengym/mktSegmentacao',
            element: <ProtectedRoute  source='1'><MktSegmentacao /></ProtectedRoute>
        },
        {
            path: '/opengym/mktSegmentacao/:mode/:idMktSegmentacao?',
            element: <ProtectedRoute  source='1'><MktSegmentacaoForm /></ProtectedRoute>
        },
        {
            path: '/opengym/mktTemplate',
            element: <ProtectedRoute  source='1'><MktTemplate /></ProtectedRoute>
        },
        {
            path: '/opengym/mktTemplate/cadastrar',
            element: <ProtectedRoute  source='1'><MktTemplateCadastrar /></ProtectedRoute>
        },
        {
            path: '/opengym/mktTemplate/editar/:idMktTemplate',
            element: <ProtectedRoute  source='1'><MktTemplateEditar /></ProtectedRoute>
        },
        {
            path: '/opengym/paymentProfile',
            element: <ProtectedRoute  source='1'><PaymentProfile /></ProtectedRoute>
        },
        {
            path: '/opengym/paymentProfile/cadastrar',
            element: <ProtectedRoute  source='1'><PaymentProfileCadastrar /></ProtectedRoute>
        },
        {
            path: '/opengym/paymentProfile/editar/:idPaymentProfile',
            element: <ProtectedRoute  source='1'><PaymentProfileEditar /></ProtectedRoute>
        },
        {
            path: '/opengym/invoice',
            element: <ProtectedRoute  source='1'><Invoice /></ProtectedRoute>
        },
        {
            path: '/opengym/invoice/:mode/:idInvoice?',
            element: <ProtectedRoute  source='1'><InvoiceForm /></ProtectedRoute>
        },
        {
            path: '/opengym/checkin.reversal',
            element: <ProtectedRoute  source='1'><CheckinReversal /></ProtectedRoute>
        },
        {
            path: '/opengym/checkin.reversed',
            element: <ProtectedRoute  source='1'><CheckinReversed /></ProtectedRoute>
        },
        {
            path: '/account1/:idUsuario',
            element: <ProtectedRoute  source='1'><Account /></ProtectedRoute>
        },

    ]
};

export default OpenGymRoute;
